import React, { useState } from 'react';
import Table from 'react-bootstrap/Table';
import { FetchDocumentUrl } from '../../api';
import { saveAs } from "file-saver";
import Loader from "../Loader"

interface Props {
  documentList: documentList
  orderId: string
}

interface documentList {
  emailAddress: string
  documents: Array<string>
}


function FilesView(props: Props) {
  const { documentList, orderId} = props;
  const [processing, setProcessing] = useState(false);
  const [showError, setShowError] = useState(false);
  const renderList = () => {
    return documentList.documents.map((d, i) => {
      return (
        <tr key={d}>
          <td>{i + 1}</td>
          <td>{d}</td>
          <td><div style={{cursor: "pointer"}} onClick={() => downloadFile(d)}>Download</div><div style={{cursor: "pointer"}} onClick={() => viewFile(d)}>View</div></td>
        </tr>
      )
    })
  }

  const saveFile = async (url:string, name:string) => {
    let data = await fetch(url)
    let text = await data.text();
    var blob = await new Blob([text], {type: "text/plain;charset=utf-8"});
    saveAs(blob, `${orderId}_${name}`);
  }

  const downloadFile = async (fileName : string) => {
    setProcessing(true);
    FetchDocumentUrl(documentList.emailAddress, fileName).then((data:any)=>{
      const docUrl = data.data.getDocumentUrl.preSignedUrl;
      if(docUrl === "no document found") {
        setShowError(true);
      }else {
        saveFile(docUrl, fileName)
      }
    }).catch((error) => {
      setShowError(true);
    }).finally(()=>{
      setProcessing(false);
    })
  };

  const viewFile = async (fileName : string) => {
    setProcessing(true);
    FetchDocumentUrl(documentList.emailAddress, fileName).then((data:any)=>{
      const docUrl = data.data.getDocumentUrl.preSignedUrl;
      if(docUrl === "no document found") {
        setShowError(true);
      }else {
        openFileInView(docUrl, fileName)
      }
    }).catch((error) => {
      setShowError(true);
    }).finally(()=>{
      setProcessing(false);
    })
  };

  const openFileInView = (url:string, name:string) => {
    saveAs(
      url,
      name
    );
  };

  return (
    <div style={{ marginTop: 20 }}>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>File Name</th>
            <th>File Options</th>
          </tr>
        </thead>
        <tbody>
          {renderList()}
        </tbody>
      </Table>
      <Loader isLoading={processing}/>
      {showError && <div style={{color: 'red'}}>Error retrieving document</div>}
    </div>
  );
}

export default FilesView;