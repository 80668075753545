import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { clearUserCreds } from "../../actions/User";
import { useAppSelector, useAppDispatch } from "../../hooks"

function NotFound() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const userName = useAppSelector(state => state.userData.userName)

    useEffect(() => {
        if(userName === '') {
            navigate("/");
        }
    },[userName, navigate])

    const clearCreds = () => {
        dispatch(clearUserCreds())
    }

    return (
        <>
            <div style={{ fontSize: 40, textAlign: "center" }}>Page Not Found</div>
            <div style={{ fontSize: 20, textAlign: "center", cursor: "pointer" }} onClick={() => clearCreds()}>Go Back To Login</div>
        </>
    );
}

export default NotFound;
