import { API, Auth } from 'aws-amplify';
import {GetDocumentsList, GetDocumentUrl } from '../graphql/query';

interface DocumentList {
  getDocumentList : Documents
}

interface Documents {
  documents : [string],
  emailAddress : string
}

export const FetchDocumentsList= async (orderNumber: string)=>{
    const idToken = await getIdToken();
    return await API.graphql({ query: GetDocumentsList, variables: {orderNumber: orderNumber}, authMode: "AMAZON_COGNITO_USER_POOLS", authToken: idToken},)as {
      data : DocumentList
    };
  }
  
  export const FetchDocumentUrl= async (emailAddress: string, documentName: string)=>{
    return await API.graphql({ query: GetDocumentUrl, variables: {documentName: documentName, emailAddress: emailAddress}, authMode: "AMAZON_COGNITO_USER_POOLS"});
  }

  export const getIdToken = async()=> {
    const curSesh = await Auth.currentSession();
    const token = curSesh.getIdToken();
    return token.getJwtToken();
  }