import * as React from "react";
import { Routes, Route } from "react-router-dom";
import SignIn from "../pages/SignIn";
import Order from "../pages/Order";
import NotFound from "../pages/NotFound";
import ForgotPassword from "../pages/ForgotPassword";

function Navigation() {
  return (
    <Routes>
    <Route path="/" element={<SignIn />} />
    <Route path="/order/" element={<Order />} />
    <Route path="/order/:orderId" element={<Order />} />
    <Route path="/forgot-password" element={<ForgotPassword />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
  );
}

export default Navigation;