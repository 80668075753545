import { CLEAR_USER, SET_USER } from "../../constants"

export const setUserCreds = (user: any | null) => (dispatch: any) => {
    dispatch({
        type: SET_USER,
        payload: {
            userName: user.payload.username,
        }
    });
};

export const clearUserCreds = () => (dispatch: any) => {
    dispatch({
        type: CLEAR_USER,
    });
};