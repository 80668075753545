
import React, { useEffect, useState } from "react";
import Container from "../../components/Container";
import FilesView from "../../components/FilesView";
import Header from "../../components/Header";
import { useParams } from "react-router-dom";
import { updateOrderId } from "../../actions/Order"
import { useAppSelector, useAppDispatch } from "../../hooks"
import { useNavigate } from "react-router-dom";
import { clearUserCreds } from "../../actions/User";
import { signOut } from "../../utils";
import Field from "../../components/Field";
import Button from "../../components/Button";
import { FetchDocumentsList } from "../../api";
import { isAuthenticated } from '../../utils'
import Loader from "../../components/Loader";

const initialDocumentState = {
    emailAddress: "",
    documents: [""]
}

function Order() {
    let params = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [orderNumber, setOrderNumber] = useState("")
    const orderId = useAppSelector(state => state.orderData.orderId)
    const userName = useAppSelector(state => state.userData.userName)
    const [documentList, setDocumentList] = useState(initialDocumentState);
    const [isLoading, setIsLoading] = useState(false);

    const logout = async () => {
        await signOut();
        dispatch(clearUserCreds());
        dispatch(updateOrderId(''));
    }

    useEffect(() => {

        const checkSession = async () => {
            if (!await isAuthenticated())
                navigate(`/`)
        }

        checkSession();

        if (params.orderId !== undefined) {
            dispatch(updateOrderId(params.orderId));

        }
            const fetchDocumentsList = async () => {
                setIsLoading(true);
                return await FetchDocumentsList(orderId)
                //setDocumentList(documentList.data.getDocumentList)
            }
            if(orderId !== "") {
            fetchDocumentsList()
                .then((data) => setDocumentList(data.data.getDocumentList))
                .catch(() => setDocumentList(initialDocumentState))
                .finally(() => setIsLoading(false));
            }
        

    }, [dispatch, orderId, navigate, params.orderId, userName]);

    const handleSearchOrder = async () => {
        dispatch(updateOrderId(orderNumber));
            navigate(`/Order/${orderNumber}`)
    }

    return (
        <Container>
            <Header title={`OrderNumber: ${orderId}`} handleClick={() => logout()} />
            {documentList.emailAddress !== "" && !isLoading && <FilesView documentList={documentList} orderId={orderId} />}
            {documentList.emailAddress === "" && !isLoading && <div style={{ fontSize: 20, textAlign: "center", marginTop: 20 }}>No documents found for this order</div>}
            <Loader isLoading={isLoading} />
            <Field title="OrderNumber" value={orderNumber} onChange={(e: any) => setOrderNumber(e.target.value)} type="text" />
            <Button title="Search" handleClick={handleSearchOrder} isLoading={false} />
        </Container>
    );
}

export default Order;