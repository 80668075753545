import { combineReducers } from "redux";

import orderData from './reducers/Order'
import userData from './reducers/User'

const rootReducer = combineReducers({
    orderData: orderData,
    userData: userData
})

export default rootReducer;