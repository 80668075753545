import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Navigation from './navigation';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import store from "./store";
import { Provider } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Amplify } from 'aws-amplify'
import awsconfig from './aws-exports';
import appSyncConfig from './aws-appsync-config'

Amplify.configure(awsconfig);
Amplify.configure(appSyncConfig);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
  <React.StrictMode>
    <BrowserRouter>
    <Navigation />
    </BrowserRouter>
  </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
