import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Field from "../../components/Field";
import ImageSrc from "../../components/ImageSrc";
import myDnaLogo from '../../assets/logos/myDNA-white-bg-logo.png'
import { Auth } from 'aws-amplify';
import ToastView from '../../components/ToastView';

interface Props {
  emailAddress: string;
  setShowResetPwd: Function;
}
const ResetPassword = (props: Props) => {
  const { emailAddress, setShowResetPwd } = props;
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");

  const [email, setEmail] = useState(emailAddress);

  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  let navigate = useNavigate();


  const resetPassword = () => {
    Auth.forgotPasswordSubmit(emailAddress, code, password)
      .then(() => {
        setMessage(
          "Updated password"
        );
        setMessageType("Success")
        setShowMessage(true)
      })
      .catch((err) => {
        setMessageType("Error")
        setMessage(
          err.message || "Something went wrong please try again later",
        );
        setShowMessage(true)
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const handleToastClose = () => {
    setShowMessage(false);
    setShowResetPwd(false);
    navigate("/");
  }

  return (
    <>
      <ToastView showMessage={showMessage} messageType={messageType} message={message} closeToast={() => handleToastClose()} />
      <ImageSrc imageSrc={myDnaLogo} imageAlt="myDnaLogo" styles={{ height: 50, margin: "20px 0 20px 0px" }} />
      <Field title="Email" value={email} onChange={(e: any) => setEmail(e.target.value)} type="text" />
      <Field title="Verification Code" value={code} onChange={(e: any) => setCode(e.target.value)} type="text" />
      <Field title="New Password" value={password} onChange={(e: any) => setPassword(e.target.value)} type="password" />
      <Button title="Reset Password" handleClick={resetPassword} isLoading={isLoading} />
    </>
  );
};

export default ResetPassword;
