
import React from 'react';

function Box({children} : any) {
    return (
      <div className="container" style={{boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 8, marginTop: 40, maxWidth: 550, padding: 25}}>
        {children}
      </div>
    );
  }
  
  export default Box;