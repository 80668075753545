import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './rootReducer'
import thunk from 'redux-thunk'

const middleWares = [
    thunk,
];

if (process.env.NODE_ENV !== `production`) {
    const { logger } = require(`redux-logger`);
    middleWares.push(logger);
}

const store = configureStore({reducer: rootReducer, middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middleWares)});
export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch