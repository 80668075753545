import React, { useState } from "react";
import Box from "../../components/Box";
import Button from "../../components/Button";
import Container from "../../components/Container";
import Field from "../../components/Field";
import ImageSrc from "../../components/ImageSrc";
import myDnaLogo from '../../assets/logos/myDNA-white-bg-logo.png'
import { Link } from "react-router-dom";
import { Auth } from 'aws-amplify';
import ToastView from '../../components/ToastView';
import ResetPassword from "./ResetPassword"

function ForgotPassword() {
    const [email, setEmail] = useState('');

    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState("");
    const [messageType, setMessageType] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [showResetPwd, setShowResetPwd] = useState(false);

    const handleReset = () => {
        Auth.forgotPassword(email)
            .then(() => {
                setMessage("Please check your email for a verification code");
                setMessageType("Success")
                setShowResetPwd(true);
                setShowMessage(true);
            })
            .catch((error) => {
                setMessageType("Error")
                if (error?.code === "UserNotFoundException") {
                    setMessage("User Not found");
                    setShowMessage(true);
                } else {
                    setMessage(error?.message);
                    setShowMessage(true);
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    return (
        <Container>
            <ToastView showMessage={showMessage} messageType={messageType} message={message} closeToast={() => setShowMessage(false)} />
            <Box>
                <ImageSrc imageSrc={myDnaLogo} imageAlt="myDnaLogo" styles={{ height: 50, margin: "20px 0 20px 0px" }} />
                {!showResetPwd && <>
                    <Field title="Email" value={email} onChange={(e: any) => setEmail(e.target.value)} type="text" />
                    <Button title="Get Verification Code" handleClick={handleReset} isLoading={isLoading} />
                    <div style={{ marginTop: 20 }}><Link to="/">Back to login</Link></div></>}
                {showResetPwd && <ResetPassword emailAddress={email} setShowResetPwd={setShowResetPwd} />}
            </Box>
        </Container>
    );
}

export default ForgotPassword;
