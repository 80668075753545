import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

interface Props {
    isLoading: boolean
}

const Loader = (props: Props) => {
    const { isLoading } = props
    return (
        <>
            {isLoading && <div style={{ textAlign: "center" }}><Spinner
                as="span"
                animation="border"
                aria-hidden="true"
                variant="primary"
            /></div>}
        </>
    )
}

export default Loader