import { SET_USER, CLEAR_USER } from '../../constants';
const INITIAL_STATE = {
    userName: "",
}

const reducer = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case SET_USER:
            return {
                ...state, userName: action.payload.userName
            }
        case CLEAR_USER:
            return {
                ...state, userName: ""
            }
        default: return state
    }
}

export default reducer;