import {UPDATE_ORDER_ID} from '../../constants';
const INITIAL_STATE = {
    orderId: "",
}

const reducer = (state = INITIAL_STATE, action:any) => {
    switch(action.type) {
        case UPDATE_ORDER_ID:
            return {
                ...state, orderId: action.payload.data
            }

        default: return state
    }
}

export default reducer;