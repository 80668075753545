import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';

interface Props {
    title: string,
    handleClick: Function,
}

const Header = (props: Props) => {
    var { title, handleClick} = props;
    return (<Navbar bg="light" expand="lg">
        <Container>
        <Navbar.Brand>{title}</Navbar.Brand>
        <Navbar className="justify-content-end">
            <Navbar.Text onClick={() => handleClick()} style={{cursor: 'pointer'}}>
                Logout
            </Navbar.Text>
        </Navbar>
        </Container>
    </Navbar>)
}

export default Header