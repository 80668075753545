
export const GetDocumentsList = /* GraphQL */ `
query getDocumentList($orderNumber: String!){
    getDocumentList(orderNumber: $orderNumber){
        documents
        emailAddress
    }
}`
export const GetDocumentUrl = /* GraphQL */ `
query getDocumentUrl($documentName: String!, $emailAddress: String!){
    getDocumentUrl(documentName: $documentName, emailAddress: $emailAddress){
        preSignedUrl
    }
}`