import React, { useEffect, useState } from "react";
import Box from "../../components/Box";
import Button from "../../components/Button";
import Container from "../../components/Container";
import Field from "../../components/Field";
import ImageSrc from "../../components/ImageSrc";
import myDnaLogo from '../../assets/logos/myDNA-white-bg-logo.png'
import { useAppSelector, useAppDispatch } from "../../hooks"
import { Link, useNavigate } from "react-router-dom";
import { Auth } from 'aws-amplify';
import { setUserCreds } from "../../actions/User"
import ToastView from '../../components/ToastView';
import { getCurrentAccessToken } from "../../utils";

function SignIn() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState("");
    const [messageType, setMessageType] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    let navigate = useNavigate();
    const dispatch = useAppDispatch();
    const orderId = useAppSelector(state => state.orderData.orderId)
    const userName = useAppSelector(state => state.userData.userName)


        useEffect(() => {
            const fetchCurrentSession = async () => {
              const currentAccessToken = await getCurrentAccessToken();
              if(currentAccessToken != null) {
                dispatch(setUserCreds(currentAccessToken));
              }
            }
            fetchCurrentSession()
              .catch(console.error);

              if (userName !== '') {
                navigate(`/Order/${orderId}`)
            }
          }, [userName, dispatch, navigate, orderId])

    const handleLogin = async () => {

        setIsLoading(true);
        Auth.signIn(email, password).then(
            (user => {
                dispatch(setUserCreds(user.signInUserSession.accessToken));
                setIsLoading(false)
            })
        ).catch(error => {
            setShowMessage(true);
            setMessageType("Error")
            setMessage(String(error))
            setIsLoading(false)
        })
    }



    return (
        <Container>
            <ToastView showMessage={showMessage} messageType={messageType} message={message} closeToast={() => setShowMessage(false)}/>
            <Box>
                <ImageSrc imageSrc={myDnaLogo} imageAlt="myDnaLogo" styles={{ height: 50, margin: "20px 0 20px 0px" }} />
                <Field title="Email" value={email} onChange={(e: any) => setEmail(e.target.value)} type="text" />
                <Field title="Password" value={password} onChange={(e: any) => setPassword(e.target.value)} type="password" />
                <Button title="Login" handleClick={handleLogin} isLoading={isLoading} />
                <div style={{marginTop: 20}}><Link to="/forgot-password">Forgot Password</Link></div>
            </Box>
        </Container>
    );
}
export default SignIn;
