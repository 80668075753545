import {UPDATE_ORDER_ID } from "../../constants"

export const updateOrderId = (orderId: string | undefined) => (dispatch: any) => {
    dispatch({
        type: UPDATE_ORDER_ID,
        payload: {
            data: orderId
        }
    });
};

export const updateUserCreds = (user: string | undefined) => (dispatch: any) => {
    dispatch({
        type: UPDATE_ORDER_ID,
        payload: {
            data: user
        }
    });
};

