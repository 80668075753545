import { Auth } from 'aws-amplify';

export const getCurrentAccessToken = async()=> {
    const curSesh = await Auth.currentSession();
    const userName = curSesh?.getAccessToken();
    return userName;
  }

  export const signOut = async()=> {
    await Auth.signOut();
  }

  export const isAuthenticated = async()=> {
    try {
    const curSesh = await Auth.currentSession();
    if (curSesh != null)
      return true
  }
    catch {
      return false
    }
  }