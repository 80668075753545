import React from 'react';
import {useStyles} from './styles'

interface Props {
    styles: React.CSSProperties,
    imageSrc: string,
    imageAlt: string
  }

const ImageSrc = (props: Props) => {
    const {styles, imageSrc, imageAlt} = props;
    const classes = useStyles();
    return (<img style={styles} src={imageSrc} alt={imageAlt} className={classes.image} />)
}

export default ImageSrc