import React from 'react';
import Form from 'react-bootstrap/Form';

interface Props {
    title: string,
    value: string,
    placeholder?: string,
    onChange?: Function,
    type?: string
  }


function Field(props: Props) {
    var {title, value, placeholder, onChange, type} = props;
    return (
        <Form.Group className="mb-3">
        <Form.Label>{title}</Form.Label>
        <Form.Control type={type} placeholder={placeholder} value={value} onChange={e => onChange && onChange(e)}/>
      </Form.Group>
    );
  }
  
  export default Field;