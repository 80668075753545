import React from 'react';
import {default as ButtonController} from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

interface Props {
    title: string,
    isLoading: Boolean,
    handleClick: Function,
  }

function Button(props: Props) {
    var {title, handleClick, isLoading} = props
    return (
    <ButtonController variant="primary" type="submit" onClick={() => handleClick()}>
        {isLoading && <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />}{!isLoading && title}
    </ButtonController>
    );
  }
  
  export default Button;