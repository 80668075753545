import React from 'react';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

interface Props {
    messageType: string,
    message: string,
    showMessage: boolean | undefined,
    closeToast: Function
  }

const ToastView = (props: Props) => {
    const {messageType, message, showMessage, closeToast} = props;
    return (<ToastContainer className="p-3" position="top-end">
    <Toast show={showMessage} onClose={() => closeToast()}>
        <Toast.Header>
            <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
            <strong className="me-auto">{messageType}</strong>
        </Toast.Header>
        <Toast.Body>{message}</Toast.Body>
    </Toast>
</ToastContainer>)
}

export default ToastView